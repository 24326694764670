import { createMuiTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ea7217',
    },
    secondary: {
      main: '#464A8A',
    },
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
  },
  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: 32,
        paddingRight: 32,
      },
    },
  },
});

export default theme;
