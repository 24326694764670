import mixpanel from 'mixpanel-browser';
import App from 'next/app';
import Head from 'next/head';
import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/node';

import npmPackage from '../../package.json';
import theme from '../theme';

Sentry.init({
  dsn: 'https://042f25ef077a4ac2835b20433708da4b@sentry.io/5176913',
  environment: process.env.SENTRY_ENV,
  enabled: process.env.NODE_ENV === 'production',
  release: `breezy@${npmPackage.version}`,
});

export default class BreezyApp extends App {
  componentDidMount() {
    mixpanel.init('640cdb654c13031cced46c6f3c0f300f');

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles?.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <React.Fragment>
        <Head>
          <title>Breezy</title>
        </Head>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Component {...pageProps} />
        </ThemeProvider>
        <style jsx global>
          {`
            body {
              overflow-x: hidden;
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}
